
(function ($) {

    $(window).on( "load", function() {
         if($('#popup').length){
         $('#popup').css('display', 'flex');
         $('#popup').addClass('show');
         }
     });
    $('#close-button,.close').on( "click", function() {
       $('#popup').hide();
       $('#popup').removeClass('show');
    });
    //"use strict";

    $(window).scroll(function () {
        if ($(this).scrollTop() > 70) {
           // $('.sticky-top').css('top', '0px');
            $('header').addClass('sticky');
            // $('.demand-devis').addClass('stiky-top');
            // $('.navbar').addClass('sticky-top');
            // $('.navbar').addClass('bg-dark');
            // $('.navbar').removeClass('toggel-icon');
           // $('.navbar .navbar-brand img').attr('src', 'build/front/images/theme1/logo-noir.png');
            //$('.navbar .navbar-brand img').attr('src', 'assets/styles/front/img/logo-noir.png');
        } else {
            $('header').removeClass('sticky');
           // $('.sticky-top').css('top', '-100px');
            // $('.demand-devis').removeClass('stiky-top');
            // $('.navbar').removeClass('sticky-top');
            // $('.navbar').removeClass('bg-dark');
            // $('.navbar').addClass('toggel-icon');
           // $('.navbar .navbar-brand img').attr('src', 'build/front/images/theme1/logo.png');
            //$('.navbar .navbar-brand img').attr('src', 'assets/styles/front/img/logo.png');
        }
    });

  //  $('.lazy').lazy();

})(jQuery);

// height slider home 
$(window).on('load resize', function(e){
  var headerH = $('header').outerHeight();
  var heightHead = headerH+'px';
  var heightcarousel = 'calc(100vh - '+heightHead+')';
  $('main.home_page .carousel.slide').css('height', heightcarousel);

  if(window.innerWidth <= 767){
    $('main.home_page .accordion-slider').css('height', heightcarousel);
  }
});

// Page LP premier bloc
$(window).on('load resize', function(e){
  if($('.section-bloc-form-lp').length){
      var headerH = $('header.header-lp').outerHeight();
      var heightHead = headerH+'px';
      var heightcarousel = 'calc(100vh - '+heightHead+')';
      $('.section-bloc-form-lp').css('height', heightcarousel);
  }
});

/*	padding-top of main if header is fixed	*/
$(window).on('load resize scroll', function(e){
	// padding-top of main if header is fixed
	if($('header').css('position') == 'fixed'){
		var headerH = $('header').outerHeight();
		$('main').css('padding-top', headerH);
	}

  if($('header.transparent').length){
    if(window.innerWidth > 767){
      $('main.home_page').css('padding-top', 0);
      $('main.home_page .carousel.slide').css('height', '100vh');
    }

    var headerH = $('header').outerHeight();
    var heightHead = headerH+'px';
    var heightcarouselCaption = 'calc(91% - '+heightHead+')';
    $('.home_page .carousel.slide .carousel-caption').css('height', heightcarouselCaption);

  }
});

$(window).on('load', function(e){

  const type_contact = document.querySelector('.type .form-select');
  if(type_contact){
    type_contact.addEventListener('change', function(){
      if(type_contact.value == "professionnel"){
          document.querySelector("input[name='societe']").setAttribute('required',true);
          document.querySelector("input[name='nom-prenom']").removeAttribute('required');
          document.querySelector('.professionnel_show').classList.remove('d-none');
          document.querySelector('.particulier_show').classList.add('d-none');
      }else{
        document.querySelector("input[name='societe']").removeAttribute('required');
        document.querySelector("input[name='nom-prenom']").setAttribute('required',true);
        document.querySelector('.professionnel_show').classList.add('d-none');
        document.querySelector('.particulier_show').classList.remove('d-none');
      }
    });
  }

});

/*-------------------------------------------------*/
/* =  IMG BACK pushes <img>'s src to background-image
/*-------------------------------------------------*/
(function($){
  $.fn.imgBack = function(options){
    
    var defaults = {
      bgSize : 'cover',
      bgPos : 'center center',
      bgRpt : 'no-repeat'
    }
    
    var prm = $.extend(defaults, options);
    var elems = this;
    var selector = this.selector;
    
    setBg();
    removeImg();
    
    /* look for a direct child img tag inside the selector,
    *  put the src attribute as inline background-image,
    *  removes the img tag
    */
    function set(){
      elems.each(function(){
        var img = $(this).children('img');
        var src = img.attr('src');
		
        if (src != undefined){
          src= src.replace(new RegExp(' ', "gi"), '%20');
          img.remove();
          $(this).css({
            'background-image':'url("'+src+'")',
            'background-size' : prm.bgSize,
            'background-position' : prm.bgPos,
            'background-repeat'	: prm.bgRpt
          });
        }
      });
    }

    function setBg(){
      elems.each(function(){
		var img = $(this).children('img');
        var src = img.attr('src');
		
        if (src != undefined){
          src= src.replace(new RegExp(' ', "gi"), '%20');
          img.remove();
        $(this).css({
		  'background-image':'url("'+src+'")',
          'background-size' : prm.bgSize,
          'background-position' : prm.bgPos,
          'background-repeat'	: prm.bgRpt
        });
		}
      });
    }

    function removeImg(){
      elems.each(function(){
        var img = $(this).children('img');
        if (img != undefined){
          img.remove();
        }
      });
    }
    
    /* reverses the process by retrieving the img url,
    *  adding it to a new child img tag with the parsed src,
    * removes inline style
    */
    function unset(){
      elems.each(function(){
        var obj = $(this);
          var src = obj.css('background-image').slice(4,-1);// removes url()
          
          //parse url in case of quotes inside the url
          if(src.substring(0,1) == '"' || src.substring(0,1) == "'"){
            src= src.substring(1);
          }

          if(src.slice(-1) == '"' || src.slice(-1) == "'"){
            src= src.slice(0, -1);
          }

          if (src != undefined){
            var img = "<img src='"+src+"' />";
            obj.append(img);
            obj.css({
              'background-image':'none',
              'background-size' : 'inherit',
              'background-position' : 'inherit',
              'background-repeat'	: 'inherit'
            });
          }
        });
    }
    
    // updates the list of container, in case you would like to use AJAX ;)
    this.update = function(){
      if(elems.length != $(selector).length){
        elems = $(selector);
      }
      set();
      return elems;
    }
    
    this.reset = function(){
      unset();
      return elems;
    }
    
    this.restart = function(){
      unset();
      this.update();
      set();
    }
    
    return this;
    
  }
  
  if($('.article-image').length){
    $('.article-image').imgBack();
  }


  if($('.img-isotope').length){
    $('.img-isotope').imgBack();
  }
  

  $('#question_reponse_carousel').owlCarousel({
      loop: false,
      center: true,
    // items: 3,
      nav: false,
      navText:["<div class='nav-btn prev-slide'><i class='fa fa-chevron-left'></i></div>","<div class='nav-btn next-slide'><i class='fa fa-chevron-right'></i></div>"],
      margin: 20,
      autoplay: false,
      dots:false,
      autoplayTimeout: 8500,
      smartSpeed: 450,
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 1
        },
        1170: {
          items: 1
        }
      }
  });
  $('.gallerie_owl_carousel').owlCarousel({
    loop: $('.gallerie_owl_carousel.owl-carousel .item-galerie').length > 2 ? true:false,
    center: false,
  // items: 3,
    nav: $('.gallerie_owl_carousel.owl-carousel .item-galerie').length > 3 ? true:false,
    navText:["<div class='nav-btn prev-slide'><i class='fa fa-chevron-left'></i></div>","<div class='nav-btn next-slide'><i class='fa fa-chevron-right'></i></div>"],
    margin: 20,
    autoplay: $('.gallerie_owl_carousel.owl-carousel .item-galerie').length > 3 ? true:false,
    dots:false,
    autoplayTimeout: 2500,
    autoplaySpeed:1500,
    smartSpeed: 450,
    autoplayHoverPause:true,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      },
      1170: {
        items: 3
      }
    }
  });
})(jQuery);



$(document).ready(function() {
  // $('.carousel').carousel({
  //     interval: 3000
  // });

  $('.carousel-indicators li').hover(
    function() {
        var slideIndex = $(this).index();
        var $carousel = $('#carouselSlide');
        
        $carousel.carousel(slideIndex);
    }
  );

  $(window).on('load resize', function(e){
    if($('header.transparent').length){
        var headerH = $('header').outerHeight();
        var heightHead = headerH+'px';
        var heightcarousel = 'calc(100vh - '+heightHead+')';
        $('.carousel.slide.modele2 .carousel-inner .carousel-item .cnt_slider').css('height', heightcarousel);
    }
  });

  $('.carousel.slide.modele2').on('slid.bs.carousel', function() {

    // Ajouter la classe spécifique à l'image active
    var activeTitre = $('.carousel-item.active').find('.titre_caption');
    activeTitre.addClass('animate__fadeOutLeft');

    var activeTexte = $('.carousel-item.active').find('.txt-caption');
    activeTexte.addClass('animate__fadeOutLeft');

    var activeImage = $('.carousel-item.active').find('.image_slider');
    activeImage.addClass('animate__fadeOutLeft');

    var activeImage = $('.carousel-item.active').find('.txt-caption [class*="btn"]');
    activeImage.addClass('animate__fadeInUp');
    var activeImage = $('.carousel-item.active').find('.txt-caption [class*="btn-"]');
    activeImage.addClass('animate__fadeInUp');
  });

/***panier header***/
  $("#showme").click(function() {
    $( "#box" ).toggleClass('active');
  });

  $('.img-parallax').each(function(){
    var img = $(this);
    var imgParent = $(this).parent();
    function parallaxImg () {
      var speed = img.data('speed');
      var imgY = imgParent.offset().top;
      var winY = $(this).scrollTop();
      var winH = $(this).height();
      var parentH = imgParent.innerHeight();
  
      // The next pixel to show on screen      
      var winBottom = winY + winH;
  
      // If block is shown on screen
      if (winBottom > imgY && winY < imgY + parentH) {
        // Number of pixels shown after block appear
        var imgBottom = ((winBottom - imgY) * speed);
        // Max number of pixels until block disappear
        var imgTop = winH + parentH;
        // Porcentage between start showing until disappearing
        var imgPercent = ((imgBottom / imgTop) * 100) + (50 - (speed * 50));
      }
      img.css({
        top: imgPercent + '%',
        transform: 'translate(-50%, -' + imgPercent + '%)'
      });
    }
    $(document).on({
      scroll: function () {
        parallaxImg();
      }, ready: function () {
        parallaxImg();
      }
    });
  });

  if ($(".list_blocs .owl-carousel.type_affiche1").length) {
    var owl = jQuery(".list_blocs .owl-carousel.type_affiche1");
    owl.owlCarousel({
    //loop: $('.list_blocs .owl-carousel.type_affiche1 .item').size() > 2 ? true:false,
    loop: $('.list_blocs .owl-carousel.type_affiche1 .article').length > 2 ? true:false,
    nav: $('.list_blocs .owl-carousel.type_affiche1 .article').length > 2 ? true:false,
    margin:0,
    responsiveClass:true,
    autoplay:true,
    autoplayTimeout:2000,
    autoplaySpeed:1500,
    autoplayHoverPause:true,
    dots:false,
    responsive:{
    0:{
    items:1,
    loop: $('.list_blocs .owl-carousel.type_affiche1 .article').length > 1 ? true:false,
    nav: $('.list_blocs .owl-carousel.type_affiche1 .article').length > 1 ? true:false,
    },
    610:{
    items:2,
    },
    768:{
    items:2,
    },
    1200:{
    items:2,
    }
    }
    });
  }

  if ($(".list_blocs .owl-carousel.type_affiche3").length) {
    var owl = jQuery(".list_blocs .owl-carousel.type_affiche3");
    owl.owlCarousel({
  //loop: $('.list_blocs .owl-carousel.type_affiche3 .item').size() > 4 ? true:false,
  loop: $('.list_blocs .owl-carousel.type_affiche3 .article').length > 4 ? true:false,
  nav: $('.list_blocs .owl-carousel.type_affiche3 .article').length > 4 ? true:false,
  margin:0,
  responsiveClass:true,
  autoplay:true,
  autoplayTimeout:2000,
  autoplaySpeed:1500,
  autoplayHoverPause:true,
  dots:false,
  responsive:{
  0:{
  items:1,
  loop: $('.list_blocs .owl-carousel.type_affiche3 .article').length > 1 ? true:false,
  nav: $('.list_blocs .owl-carousel.type_affiche3 .article').length > 1 ? true:false,

  },
  610:{
  items:2,
  loop: $('.list_blocs .owl-carousel.type_affiche3 .article').length > 2 ? true:false,
  nav: $('.list_blocs .owl-carousel.type_affiche3 .article').length > 2 ? true:false,

  },
  768:{
  items:2,
  loop: $('.list_blocs .owl-carousel.type_affiche3 .article').length > 2 ? true:false,
  nav: $('.list_blocs .owl-carousel.type_affiche3 .article').length > 2 ? true:false,

  },
  992:{
    items:3,
  loop: $('.list_blocs .owl-carousel.type_affiche3 .article').length > 3 ? true:false,
    nav: $('.list_blocs .owl-carousel.type_affiche3 .article').length > 3 ? true:false,

  },
  1200:{
  items:4,
  }
  }
    });
  }

  if ($(".list_blocs .owl-carousel").length) {
    var owl = jQuery(".list_blocs .owl-carousel");
    owl.owlCarousel({
  //loop: $('.list_blocs .owl-carousel .item').size() > 3 ? true:false,
    loop: $('.list_blocs .owl-carousel .article').length > 3 ? true:false,
    nav: $('.list_blocs .owl-carousel .article').length > 3 ? true:false,

  margin:0,
  responsiveClass:true,
  autoplay:true,
  autoplayTimeout:2000,
  autoplaySpeed:1500,
  autoplayHoverPause:true,
  dots:false,
  responsive:{
  0:{
  items:1,
    loop: $('.list_blocs .owl-carousel .article').length > 1 ? true:false,
    nav: $('.list_blocs .owl-carousel .article').length > 1 ? true:false,
  },
  610:{
  items:2,
    loop: $('.list_blocs .owl-carousel .article').length > 2 ? true:false,
    nav: $('.list_blocs .owl-carousel .article').length > 2 ? true:false,
  },
  768:{
  items:2,
    loop: $('.list_blocs .owl-carousel .article').length > 2 ? true:false,
    nav: $('.list_blocs .owl-carousel .article').length > 2 ? true:false,
  },
  1200:{
  items:3,
  }
  }
    });
  }


/***** owl carousel***/
$(document).ready(function(){
  if ($(".all-partners .owl-carousel").length) {
    var owl = jQuery(".all-partners .owl-carousel");
    owl.owlCarousel({
          loop: $('.all-partners .owl-carousel .item_logo').length > 4 ? true:false,
          nav: $('.all-partners .owl-carousel .item_logo').length > 4 ? true:false,
          margin:0,
          responsiveClass:true,
          autoplay:false,
          dots:false,
          autoplayTimeout:2000,
          autoplaySpeed:1000,
          responsive:{
          0:{
            items:1,
            loop: $('.all-partners .owl-carousel .item_logo').length > 1 ? true:false,
            nav: $('.all-partners .owl-carousel .item_logo').length > 1 ? true:false,
          },
          610:{
            items:2,
            loop: $('.all-partners .owl-carousel .item_logo').length > 2 ? true:false,
            nav: $('.all-partners .owl-carousel .item_logo').length > 2 ? true:false,
          },
          768:{
            items:3,
            loop: $('.all-partners .owl-carousel .item_logo').length > 3 ? true:false,
            nav: $('.all-partners .owl-carousel .item_logo').length > 3 ? true:false,
          },
          992:{
            items:4,
            loop: $('.all-partners .owl-carousel .item_logo').length > 4 ? true:false,
            nav: $('.all-partners .owl-carousel .item_logo').length > 4 ? true:false,
          }
        }
    });
  }

  if ($(".owl-carousel#question_reponse_carousel").length) {
    var owl = jQuery(".owl-carousel#question_reponse_carousel");
    owl.owlCarousel({
      loop: true,
      nav: false,
      margin:0,
      responsiveClass:true,
      autoplay:true,
      autoplayTimeout:2000,
      autoplaySpeed:1500,
      autoplayHoverPause:true,
      dots:false,
      responsive:{
        0:{
        items:1,
        },
      }
    });
  }

  if ($(".carousel-bon-cadeaux").length) {
    var owl = jQuery(".carousel-bon-cadeaux");
    owl.owlCarousel({
          loop: true,
          nav: true,
          margin:0,
          responsiveClass:true,
          autoplay:false,
          dots:false,
          autoplayTimeout:5000,
          autoplaySpeed:1500,
          items:1,
          responsive:{
          0:{
            items:1,
          }
        }
    });
  }
})




var $grid = $('.grid').isotope({
  itemSelector: '.item'
});

 // layout Isotope after each image loads
  $grid.imagesLoaded().progress( function() {
    $grid.isotope('layout');
  }); 

new WOW({
  boxClass:     'wow',      // default
  animateClass: 'animated', // default
  offset:       10,          // default
  mobile:       true,       // default
  live:         true        // default
}).init();

});

//***************** Liste faqs ***********************/
var acc = document.getElementsByClassName('faqs-title');
var i;

if (acc) {
  for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function () {
          this.classList.toggle('active');
          var panel = this.nextElementSibling;
          if (panel.style.maxHeight) {
              panel.style.maxHeight = null;
          } else {
              panel.style.maxHeight = panel.scrollHeight + 'px';
          }
      });
  }
}

/** counter animation */

function detect_visibility() {
     var top_of_element = $(".counter").offset().top;
     var bottom_of_element = $(".counter").offset().top + $(".counter").outerHeight();
     var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
     var top_of_screen = $(window).scrollTop();
    
     if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
      var counted = 0;
      if ($('.counter:not(.animation-finished)').length) {
       var oTop = $('.counter').offset().top - window.innerHeight;
       if (counted === 0 && $(window).scrollTop() > oTop) {
        $('.count:not(.animation-finished)').each(function() {
         var $this = $(this);
         var countTo = $this.attr('data-count');
         var currentCount = parseFloat($this.text().replace(/[^0-9.-]/g, ''));
         var targetCount = parseFloat(countTo.replace(/[^0-9.-]/g, ''));
         $this.addClass('animation-finished');
    
         var currentText = $this.text();
         var targetText = countTo;
    
         var currentSpecialChar = currentText.replace(currentCount, '');
         var targetSpecialChar = targetText.replace(targetCount, '');
    
         var countDiff = targetCount - currentCount;
         var increment = countDiff / 100;
         var currentIncrement = currentCount;
    
         var counterInterval = setInterval(function() {
          if (currentIncrement < targetCount) {
           currentIncrement += increment;
           var countText = Math.floor(currentIncrement).toLocaleString();
           $this.text(countText);
          } else {
           clearInterval(counterInterval);
           $this.text(targetText);
           
          }
         }, 20);
    
        //  setTimeout(function() {
        //   $this.text(targetText);
        //  }, 2000); // Afficher les caractères spéciaux à la fin de l'incrémentation (2 secondes)
        });
        counted = 1;
       }
      }
     } else {
        $('.count:not(.animation-finished)').text(0);
     }
  }







// detect when element gets visible on scroll
if($('.counter').length){
  $(window).scroll(function() {
    detect_visibility();
  });
}

(function($){
  $('.click-categorie').click(function(){
     if($(this).hasClass('up-cat')){
        $(this).removeClass('up-cat').addClass('down-cat');
        $(this).parent().next().removeClass('d-block').fadeOut("slow");
     }else if($(this).hasClass('down-cat')){
        $(this).removeClass('down-cat').addClass('up-cat');
        $(this).parent().next().removeClass('d-none').fadeIn("slow");
     }
  });
})( jQuery );

function checkCookieRecaptcha(formulaire){
  //$(".alert-cookie-recaptcha").hide();
  var checkCookieRecaptcha = (tarteaucitron.cookie.read().indexOf('recaptcha3=true') >= 0);
  if(checkCookieRecaptcha){
    // Cookie recaptcha accepted
    formulaire.find('[type="submit"]').removeAttr('disabled' ).css({'cursor': '', 'opacity': ''});
    $('.alert-cookie-recaptcha').hide();
  }else{
    // Cookie recaptcha not accepted
    formulaire.find('[type="submit"]').attr('disabled', 'disabled').css({'cursor': 'not-allowed', 'opacity': 0.4});
    $('.alert-cookie-recaptcha').show();
  }
  
  return checkCookieRecaptcha;
}

window.activateRecaptchaButtonForm = function(){
    $(".alert-cookie-recaptcha").each(function(){
      if($(this).closest('form').length){
        checkCookieRecaptcha($(this).closest('form'));
      }
    })
}
window.addEventListener("load", function () {
    activateRecaptchaButtonForm();
});


// DÃ©but Boutons téléphones LP
window.addEventListener("DOMContentLoaded", (event) => {

  var selectElements = document.querySelectorAll('.affiche-tel');

  if(window.innerWidth > 767){

    if(selectElements){
      selectElements.forEach(function(item){
        item.addEventListener('click', function(e){ 
          e.preventDefault();
          $(item).find('span').text(item.getAttribute('data-tel'));
          item.setAttribute("onclick","");    
        })
      })
    } 
  }else{
    if(selectElements){
      selectElements.forEach(function(item){
         var tel_click = item.getAttribute("data-phone-number")
          /*item.setAttribute("onclick", "window.location.href = 'tel:" + tel_click + "';"); */
          item.addEventListener('click', function(e){ 
          $.ajax({
            url: '/clic-telephone',
            method: 'POST',
            data: { phoneNumber: tel_click },
            success: function(response) {
            },
            error: function(error) {
              console.error("Erreur lors de l'enregistrement du clic : ", error);
            }
          });
        })
      })
    }
  }

});
// Fin Boutons téléphones LP

/** tracking telephone */
$(document).ready(function() {
  $(".tel-header-1, .tel-header-2, .tel-footer-1, .tel-footer-2").on("click", function(event) {
    var phoneNumber = $(this).data("phone-number");
    event.preventDefault();
    window.location.href = "tel:" + phoneNumber;
    $.ajax({
      url: '/clic-telephone',
      method: 'POST',
      data: { phoneNumber: phoneNumber },
      success: function(response) {
      },
      error: function(error) {
        console.error("Erreur lors de l'enregistrement du clic : ", error);
      }
    });
  });


/* if ($('header .btn-header.tel-header-1').length > 0) {
  if(window.innerWidth < 767){
    var clonedElement = $('header .btn-header.tel-header-1').clone();
    clonedElement.addClass('sticky-tel');
    $('header.header-lp').append(clonedElement);
  }
} */

});



$(document).ready(function(){
      var rec = new webkitSpeechRecognition();
      var recognizing = false;
      rec.lang = 'fr-FR';
      rec.continuous = true;
      rec.interimResults = true;
  

      // Fonction jquery pour controler l'écoute
      
      $('#recButton').addClass("notRec");
      
      $('#recButton').click(function(){
            if($('#recButton').hasClass('notRec')){
                    $('#recButton').removeClass("notRec");
                    $('#recButton').addClass("Rec");
                    listen(rec);
                    recognizing = true;
              }else{
                    $('#recButton').removeClass("Rec");
                    $('#recButton').addClass("notRec");
                    stopListen(rec);
                    recognizing = false;
            }

            setTimeout(() => {
              if (recognizing == true) {
                  $('#recButton').removeClass("Rec");
                  $('#recButton').addClass("notRec");
                  stopListen(rec); 
                  recognizing = false;
              }
            }, 5000)
      });
});

function listen(rec) {
 
  rec.start();
  //alert("reconnaissance vocale activée")
  //$('.resRecord').focus();
  rec.onresult = function(e) {
       //console.log(e.results);
       let result = e.results.item(e.resultIndex);
      if (result.isFinal === true) {
          let text = $('.resRecord').val();
          text += result[0].transcript;
          $('.resRecord').val(text);
         //document.getElementById('story_story').textContent += result[0].transcript;
      }
  }
}

// Fonction stopper l'écoute
function stopListen(rec) {
  rec.stop();
  alert("reconnaissance vocale désactivée")
}