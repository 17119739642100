import './plugins/jquery.fancybox.min.css'
import './scss/default.scss'
//import './css/style.css'
import './css/owl.carousel.min.css' 
import './css/jplist.css' 

import './js/vendor/fancybox.min.js'

import './js/lazysizes.min'
//import './js/wow.min'
//import './js/isotope'

import './js/vendor/owl.carousel.min'
import './js/main'
import './js/jplist.core.min'
import './js/jplist.pagination-bundle.min'
import './js/jplist.sort-bundle.min'
import './js/jplist.filter-toggle-bundle.min'
import './js/jplist.filter-dropdown-bundle.min'
import './js/jplist.textbox-filter.min'
import './js/jplist.history-bundle.min'