/**

* jPList - jQuery Data Grid Controls 5.2.0.287 - http://jplist.com 

* Copyright 2016 Miriam Zusin

*/

(function(){var d=function(a,e){var b,c;if(a&&a.controller&&a.controller.collection){b=a.controller.collection.dataitems.length;jQuery.isNumeric(e.index)&&0<=e.index&&e.index<=a.controller.collection.dataitems.length&&(b=Number(e.index));e.$item&&a.controller.collection.addDataItem(e.$item,a.controller.collection.paths,b);if(e.$items)if(c=e.$items,jQuery.isArray(c))for(var d=c.length-1;0<=d;d--)a.controller.collection.addDataItem(c[d],a.controller.collection.paths,b);else c=c.find(a.options.itemPath).addBack(a.options.itemPath),

    jQuery(c.get().reverse()).each(function(){a.controller.collection.addDataItem(jQuery(this),a.controller.collection.paths,b)});a.observer.trigger(a.observer.events.unknownStatusesChanged,[!1])}},b=function(a,e){var c;a&&a.controller&&a.controller.collection&&(e.$item&&(a.controller.collection.delDataitem(e.$item),e.$item.remove()),e.$items&&(c=e.$items,jQuery.isArray(e.$items)&&(c=jQuery(e.$items).map(function(){return this.toArray()})),a.controller.collection.delDataitems(c),c.remove()),a.observer.trigger(a.observer.events.unknownStatusesChanged,
    
    [!1]))},c=function(a,e,c){switch(e){case "add":d(a,c);break;case "del":b(a,c);break;case "empty":a&&a.controller&&a.controller.collection&&(a.controller.collection.empty(),a.observer.trigger(a.observer.events.unknownStatusesChanged,[!1]));break;case "getDataItems":return e=null,a.controller&&a.controller.collection&&(e=a.controller.collection.dataitems),e;case "addControl":a&&a.panel&&a.controller&&(a.panel.addControl(c.$control),a.controller.addPaths(a.panel.paths))}},a=function(a){a.observer.on(a.observer.events.knownStatusesChanged,
    
    function(e,c){var b;if(c&&(b=a.panel.mergeStatuses(c))&&0<b.length&&(a.storage.save(b),a.controller.renderStatuses(b,a.history.getLastStatus()),b=!1,c)){for(var d=0;d<c.length;d++)if(c[d].isAnimateToTop){b=!0;break}b&&(b=jQuery(a.options.animateToTop).offset().top,jQuery("html, body").animate({scrollTop:b},a.options.animateToTopDuration))}});a.observer.on(a.observer.events.unknownStatusesChanged,function(e,c){var b;b=a.panel.getStatuses(c);0<b.length&&(a.storage.save(b),a.controller.renderStatuses(b,
    
    a.history.getLastStatus()))});a.observer.on(a.observer.events.statusesAppliedToList,function(e,c,b){a.panel.setStatuses(b);jQuery.fn.jplist.DeepLinksService.updateUrlPerControls(a.options,a.panel.getDeepLinksURLPerControls())});a.observer.on(a.observer.events.statusesChangedByDeepLinks,function(e,c,b){a.panel.statusesChangedByDeepLinks(c,b)});a.$root.find(a.options.iosBtnPath).on("click",function(){jQuery(this).next(a.options.panelPath).toggleClass("jplist-ios-show")})},e=function(c,e){var b={observer:null,
    
    panel:null,controller:null,storage:null,$root:e};b.options=jQuery.extend(!0,{debug:!1,command:"init",commandData:{},itemsBox:".list",itemPath:".list-item",panelPath:".panel",noResults:".jplist-no-results",redrawCallback:"",iosBtnPath:".jplist-ios-button",animateToTop:"html, body",animateToTopDuration:0,effect:"",duration:300,fps:24,storage:"",storageName:"jplist",cookiesExpiration:-1,deepLinking:!1,hashStart:"#",delimiter0:":",delimiter1:"|",delimiter2:"~",delimiter3:"!",historyLength:10},c);b.observer=
    
    new jQuery.fn.jplist.PubSub(b.$root,b.options);b.history=new jQuery.fn.jplist.History(b.$root,b.options,b.observer);b.panel=new jQuery.fn.jplist.PanelController(e,b.options,b.history,b.observer);b.storage=new jQuery.fn.jplist.Storage(b.options.storage,b.options.storageName,b.options.cookiesExpiration);b.itemControls=new jQuery.fn.jplist.ItemControlCollection(b.options,b.observer,b.history,b.$root);b.controller=new jQuery.fn.jplist.DOMController(b.$root,b.options,b.observer,b.panel.paths);a(b);b.options.deepLinking?
    
    b.panel.setStatusesByDeepLink():b.panel.setStatusesFromStorage();return jQuery.extend(this,b)};jQuery.fn.jplist=function(a){if(a.command&&"init"!==a.command){var b;if(b=this.data("jplist"))return c(b,a.command,a.commandData)}else return this.each(function(){var b,c=jQuery(this);b=new e(a,c);c.data("jplist",b)})};jQuery.jplist={};jQuery.fn.jplist.controls=jQuery.fn.jplist.controls||{};jQuery.fn.jplist.itemControls=jQuery.fn.jplist.itemControls||{};jQuery.fn.jplist.controlTypes={};jQuery.fn.jplist.itemControlTypes=
    
    {};jQuery.fn.jplist.settings={};jQuery.fn.jplist.FiltersService=jQuery.fn.jplist.FiltersService||{};jQuery.fn.jplist.SortService=jQuery.fn.jplist.SortService||{}})();(function(){var d=function(b){var c=jQuery.fn.trigger;b.options.debug&&(jQuery.fn.trigger=function(a){for(var e in b.events)if(b.events[e]==a){console.log(e,arguments);break}return c.apply(this,arguments)})};jQuery.fn.jplist.PubSub=function(b,c){var a={options:c,$root:b,events:{unknownStatusesChanged:"1",knownStatusesChanged:"2",statusesChangedByDeepLinks:"3",listSorted:"4",listFiltered:"5",listPaginated:"6",dataItemAdded:"7",dataItemRemoved:"8",collectionReadyEvent:"9",statusesAppliedToList:"10"}};
    
    d(a);return jQuery.extend(!0,jQuery({}),this,a)}})();(function(){jQuery.fn.jplist.History=function(d,b,c){this.options=b;this.observer=c;this.$root=d;this.statusesQueue=[];this.listStatusesQueue=[]};jQuery.fn.jplist.History.prototype.addStatuses=function(d){for(var b=0;b<d.length;b++)this.statusesQueue.push(d[b]),this.statusesQueue.length>this.options.historyLength&&this.statusesQueue.shift()};jQuery.fn.jplist.History.prototype.getLastStatus=function(){var d=null;0<this.statusesQueue.length&&(d=this.statusesQueue[this.statusesQueue.length-1]);return d};
    
    jQuery.fn.jplist.History.prototype.getLastList=function(){var d=null;0<this.listStatusesQueue.length&&(d=this.listStatusesQueue[this.listStatusesQueue.length-1]);return d};jQuery.fn.jplist.History.prototype.addList=function(d){this.listStatusesQueue.push(d);this.listStatusesQueue.length>this.options.historyLength&&this.listStatusesQueue.shift()};jQuery.fn.jplist.History.prototype.popList=function(){var d=null;0<this.listStatusesQueue.length&&(d=this.listStatusesQueue.pop());return d}})();(function(){jQuery.fn.jplist.StatusDTO=function(d,b,c,a,e,h,g,f){this.action=b;this.name=d;this.type=c;this.data=a;this.inStorage=e;this.inAnimation=h;this.isAnimateToTop=g;this.inDeepLinking=f}})();(function(){jQuery.fn.jplist.StatusesService=jQuery.fn.jplist.StatusesService||{};jQuery.fn.jplist.StatusesService.getStatusesByAction=function(b,c){for(var a=[],e,d=0;d<c.length;d++)e=c[d],e.action===b&&a.push(e);return a};jQuery.fn.jplist.StatusesService.getSortStatuses=function(b){var c,a=[],e;b=jQuery.fn.jplist.StatusesService.getStatusesByAction("sort",b);if(jQuery.isArray(b))for(var d=0;d<b.length;d++)if((c=b[d])&&c.data&&c.data.sortGroup&&jQuery.isArray(c.data.sortGroup)&&0<c.data.sortGroup.length)for(var g=
    
    0;g<c.data.sortGroup.length;g++)e=new jQuery.fn.jplist.StatusDTO(c.name,c.action,c.type,c.data.sortGroup[g],c.inStorage,c.inAnimation,c.isAnimateToTop,c.inDeepLinking),a.push(e);else a.push(c);return a};jQuery.fn.jplist.StatusesService.getFilterStatuses=function(b){var c,a,e=[];b=jQuery.fn.jplist.StatusesService.getStatusesByAction("filter",b);if(jQuery.isArray(b))for(var d=0;d<b.length;d++)(c=b[d])&&c.data&&c.data.filterType&&(a=jQuery.fn.jplist.DTOMapperService.filters[c.data.filterType],jQuery.isFunction(a)&&
    
    e.push(c));return e};var d=function(b,c,a,e){for(var d=[],g,f=0;f<b.length;f++)g=b[f],g[c]===a&&(e&&(g.initialIndex=f),d.push(g));return d};jQuery.fn.jplist.StatusesService.add=function(b,c,a){var e,h,g;if(0===b.length)b.push(c);else if(e=d(b,"action",c.action,!0),0===e.length)b.push(c);else if(h=d(e,"name",c.name,!1),0===h.length)b.push(c);else{g=!1;for(var f=0;f<h.length;f++)e=h[f],e.type===c.type&&(g=!0,a&&(b[e.initialIndex]=c));g||b.push(c)}}})();(function(){jQuery.fn.jplist.DTOMapperService=jQuery.fn.jplist.DTOMapperService||{};jQuery.fn.jplist.DTOMapperService.filters={};jQuery.fn.jplist.DTOMapperService.filters.TextFilter=function(d,b){var c=new jQuery.fn.jplist.PathModel(d.data.path,null);return jQuery.fn.jplist.FiltersService.textFilter(d.data.value,c,b,d.data.ignore,d.data.mode,d.data.not,d.data.and,d.data.or)};jQuery.fn.jplist.DTOMapperService.filters.path=function(d,b){var c=new jQuery.fn.jplist.PathModel(d.data.path,null);return jQuery.fn.jplist.FiltersService.pathFilter(c,
    
    b)};jQuery.fn.jplist.DTOMapperService.filters.range=function(d,b){var c=new jQuery.fn.jplist.PathModel(d.data.path,null);return jQuery.fn.jplist.FiltersService.rangeFilter(c,b,d.data.min,d.data.max,d.data.prev,d.data.next)};jQuery.fn.jplist.DTOMapperService.filters.date=function(d,b){var c=new jQuery.fn.jplist.PathModel(d.data.path,null);return jQuery.fn.jplist.FiltersService.dateFilter(d.data.year,d.data.month,d.data.day,c,b,d.data.format)};jQuery.fn.jplist.DTOMapperService.filters.dateRange=function(d,
    
    b){var c=new jQuery.fn.jplist.PathModel(d.data.path,null);return jQuery.fn.jplist.FiltersService.dateRangeFilter(c,b,d.data.format,d.data.prev_year,d.data.prev_month,d.data.prev_day,d.data.next_year,d.data.next_month,d.data.next_day)};jQuery.fn.jplist.DTOMapperService.filters.pathGroup=function(d,b){return jQuery.fn.jplist.FiltersService.pathGroupFilter(d.data.pathGroup,b)};jQuery.fn.jplist.DTOMapperService.filters.textGroup=function(d,b){return jQuery.fn.jplist.FiltersService.textGroupFilter(d.data.textGroup,
    
    d.data.logic,d.data.path,d.data.ignoreRegex,b,d.data.mode)};jQuery.fn.jplist.DTOMapperService.filters.textFilterPathGroup=function(d,b){return jQuery.fn.jplist.FiltersService.textFilterPathGroup(d.data.textAndPathsGroup,d.data.ignoreRegex,b,d.data.mode)};jQuery.fn.jplist.DTOMapperService.sort={};jQuery.fn.jplist.DTOMapperService.sort.text=function(d,b,c){var a=new jQuery.fn.jplist.PathModel(d.data.path,d.data.type);return jQuery.fn.jplist.SortService.textHelper(b,c,d.data.order,a,d.data.ignore||"")};
    
    jQuery.fn.jplist.DTOMapperService.sort.number=function(d,b,c){var a=new jQuery.fn.jplist.PathModel(d.data.path,d.data.type);return jQuery.fn.jplist.SortService.numbersHelper(b,c,d.data.order,a)};jQuery.fn.jplist.DTOMapperService.sort.datetime=function(d,b,c){var a=new jQuery.fn.jplist.PathModel(d.data.path,d.data.type);return jQuery.fn.jplist.SortService.datetimeHelper(b,c,d.data.order,a,d.data.dateTimeFormat||"")}})();(function(){jQuery.fn.jplist.PathModel=function(d,b){this.jqPath=d;this.dataType=b};jQuery.fn.jplist.PathModel.prototype.isEqual=function(d,b){var c=!1;b?this.jqPath===d.jqPath&&(c=!0):this.jqPath===d.jqPath&&this.dataType===d.dataType&&(c=!0);return c}})();(function(){jQuery.fn.jplist.DataItemMemberModel=function(d,b){this.$element=d;this.path=b;this.text=d.text();this.html=d.html()}})();(function(){var d=function(b,c){for(var a,e,d=[],g=0;g<c.length;g++)a=c[g],e=b.$item.find(a.jqPath).addBack(a.jqPath),0<e.length&&(a=new jQuery.fn.jplist.DataItemMemberModel(e,a),d.push(a));a=new jQuery.fn.jplist.PathModel("",null);a=new jQuery.fn.jplist.DataItemMemberModel(b.$item,a);d.push(a);return d};jQuery.fn.jplist.DataItemModel=function(b,c,a){this.pathitems=[];this.jqElement=this.$item=b;this.index=a;this.html=jQuery.fn.jplist.HelperService.getOuterHtml(b);this.pathitems=d(this,c)};jQuery.fn.jplist.DataItemModel.prototype.findPathitem=
    
    function(b){for(var c=null,a,e=0;e<this.pathitems.length;e++)if(a=this.pathitems[e],a.path.isEqual(b,!0)){c=a;break}return c};jQuery.fn.jplist.DataItemModel.prototype.addPaths=function(b){this.pathitems=this.pathitems.concat(d(this,b))}})();(function(){var d=function(a,b){var c,e,d;c=jQuery.fn.jplist.StatusesService.getFilterStatuses(b);if(0<c.length){for(var h=0;h<c.length;h++)e=c[h],d=jQuery.fn.jplist.DTOMapperService.filters[e.data.filterType],a.dataview=d(e,a.dataview);a.observer.trigger(a.observer.events.listFiltered,[b,a])}},b=function(a,b){var c,e=null,d=0,h=0;c=jQuery.fn.jplist.StatusesService.getStatusesByAction("paging",b);if(0<c.length){for(var g=0;g<c.length;g++)if(e=c[g],e.data&&(jQuery.isNumeric(e.data.currentPage)&&(d=
    
    e.data.currentPage),jQuery.isNumeric(e.data.number)||"all"===e.data.number))h=e.data.number;e=new jQuery.fn.jplist.PaginationService(d,h,a.dataview.length);for(d=0;d<c.length;d++)c[d].data&&(c[d].data.paging=e);a.dataview=jQuery.fn.jplist.FiltersService.pagerFilter(e,a.dataview);a.observer.trigger(a.observer.events.listPaginated,[b,a])}},c=function(a,b){var c=[],c=jQuery.fn.jplist.StatusesService.getSortStatuses(b);0<c.length&&(jQuery.fn.jplist.SortService.doubleSort(c,a.dataview),a.observer.trigger(a.observer.events.listSorted,
    
    [b,a]))},a=function(a){return jQuery(a.dataview).map(function(a,b){return b.jqElement.get()})},e=function(a){return jQuery(a.dataitems).map(function(a,b){return b.jqElement.get()})},h=function(a){a.dataview=jQuery.merge([],a.dataitems)},g=function(a,b){for(var c,e=-1,d,h=0;h<a.dataitems.length;h++)if(c=a.dataitems[h],c=jQuery.fn.jplist.HelperService.getOuterHtml(c.jqElement),d=jQuery.fn.jplist.HelperService.getOuterHtml(b),c===d){e=h;break}return e},f=function(a,b){var c;c=g(a,b);-1!==c&&(a.dataitems.splice(c,
    
    1),a.observer.trigger(a.observer.events.dataItemRemoved,[b,a.dataitems]))},k=function(a,b){b.each(function(){f(a,jQuery(this))})},l=function(a,b,c,e){b=new jQuery.fn.jplist.DataItemModel(b,c,e);a.dataitems.splice(e,0,b);a.observer.trigger(a.observer.events.dataItemAdded,[b,a.dataitems])},m=function(a,b,c,e,d){for(var h;c<b.length;c++)h=b.eq(c),3!==h.get(0).nodeType&&(l(a,h,e,c),c+1<b.length&&0===c%50&&window.setTimeout(function(){m(a,b,c,e,d)},0))};jQuery.fn.jplist.Dataitems=function(a,b,c){this.dataitems=
    
    [];this.dataview=[];this.paths=c;this.observer=a;0<b.length&&(m(this,b,0,c,0),h(this));this.observer.trigger(this.observer.events.collectionReadyEvent,[this])};jQuery.fn.jplist.Dataitems.prototype.addPaths=function(a){for(var b=0;b<this.dataitems.length;b++)this.dataitems[b].addPaths(a);h(this)};jQuery.fn.jplist.Dataitems.prototype.applyStatuses=function(e){h(this);c(this,e);d(this,e);b(this,e);this.observer.trigger(this.observer.events.statusesAppliedToList,[this,e]);return a(this)};jQuery.fn.jplist.Dataitems.prototype.filter=
    
    function(a){d(this,a)};jQuery.fn.jplist.Dataitems.prototype.sort=function(a){c(this,a)};jQuery.fn.jplist.Dataitems.prototype.pagination=function(a){b(this,a)};jQuery.fn.jplist.Dataitems.prototype.dataviewToJqueryObject=function(){return a(this)};jQuery.fn.jplist.Dataitems.prototype.dataitemsToJqueryObject=function(){return e(this)};jQuery.fn.jplist.Dataitems.prototype.resetDataview=function(){h(this)};jQuery.fn.jplist.Dataitems.prototype.empty=function(){e(this).detach();this.dataitems=[];this.dataview=
    
    []};jQuery.fn.jplist.Dataitems.prototype.addDataItem=function(a,b,c){l(this,a,b,c)};jQuery.fn.jplist.Dataitems.prototype.addDataItems=function(a,b,c){m(this,a,0,b,c);h(this)};jQuery.fn.jplist.Dataitems.prototype.delDataitem=function(a){f(this,a)};jQuery.fn.jplist.Dataitems.prototype.delDataitems=function(a){k(this,a)};jQuery.fn.jplist.Dataitems.prototype.indexOf=function(a){return g(this,a)};jQuery.fn.jplist.Dataitems.prototype.dataviewToString=function(){var a,b="",c;for(c=0;c<this.dataview.length;c++)a=
    
    this.dataview[c],b+=a.html;return b}})();(function(){jQuery.fn.jplist.PaginationService=function(d,b,c){c=Number(c);isNaN(c)&&(c=0);c=this.itemsNumber=c;jQuery.isNumeric(b)?(b=Number(b),isNaN(b)&&(b=c)):b=c;this.itemsOnPage=b;b=(b=this.itemsOnPage)?Math.ceil(this.itemsNumber/b):0;b=this.pagesNumber=b;d=Number(d);isNaN(d)&&(d=0);d>b-1&&(d=0);this.currentPage=d;this.start=this.currentPage*this.itemsOnPage;d=this.itemsNumber;b=this.start+this.itemsOnPage;b>d&&(b=d);this.end=b;d=this.currentPage;this.prevPage=0>=d?0:d-1;d=this.currentPage;b=
    
    this.pagesNumber;this.nextPage=0===b?0:d>=b-1?b-1:d+1}})();(function(){jQuery.fn.jplist.PaginationGoogleService=function(d,b,c){c=Number(c);isNaN(c)&&(c=0);c=this.itemsNumber=c;jQuery.isNumeric(b)?(b=Number(b),isNaN(b)&&(b=c)):b=c;this.itemsOnPage=b;b=(b=this.itemsOnPage)?Math.ceil(this.itemsNumber/b):0;b=this.pagesNumber=b;d=Number(d);isNaN(d)&&(d=0);d>b-1&&(d=0);this.currentPage=d;this.start=this.currentPage*this.itemsOnPage;d=this.itemsNumber;b=this.start+this.itemsOnPage;b>d&&(b=d);this.end=b;d=this.currentPage;this.prevPage=0>=d?0:d-1;d=this.currentPage;
    
    b=this.pagesNumber;this.nextPage=0===b?0:d>=b-1?b-1:d+1}})();(function(){jQuery.fn.jplist.FiltersService.dateFilter=function(d,b,c,a,e,h){for(var g,f,k=[],l=0;l<e.length;l++)if(g=e[l],f=g.findPathitem(a))jQuery.isNumeric(d)&&jQuery.isNumeric(b)&&jQuery.isNumeric(c)?(f=jQuery.fn.jplist.HelperService.formatDateTime(f.text,h))&&jQuery.isFunction(f.getFullYear)&&(f.setHours(0),f.setMinutes(0),f.setSeconds(0),f.getFullYear()===d&&f.getMonth()===b&&f.getDate()===c&&k.push(g)):k.push(g);return k}})();(function(){jQuery.fn.jplist.FiltersService.dateRangeFilter=function(d,b,c,a,e,h,g,f,k){for(var l=[],m,n,p,q,r=0;r<b.length;r++)if(m=b[r],n=m.findPathitem(d))p=!jQuery.isNumeric(a)||!jQuery.isNumeric(e)||!jQuery.isNumeric(h),q=!jQuery.isNumeric(g)||!jQuery.isNumeric(f)||!jQuery.isNumeric(k),p||q?l.push(m):(q=jQuery.fn.jplist.HelperService.formatDateTime(n.text,c))&&jQuery.isFunction(q.getFullYear)&&(n=new Date(a,e,h),p=new Date(g,f,k),q.setHours(0),q.setMinutes(0),q.setSeconds(0),q>=n&&q<=p&&l.push(m));
    
    return l}})();(function(){jQuery.fn.jplist.FiltersService.pagerFilter=function(d,b){return b.slice(d.start,d.end)}})();(function(){jQuery.fn.jplist.FiltersService.pathFilter=function(d,b){for(var c,a,e=[],h=0;h<b.length;h++)c=b[h],"default"===d.jqPath?e.push(c):(a=c.findPathitem(d))&&e.push(c);return e}})();(function(){jQuery.fn.jplist.FiltersService.pathGroupFilter=function(d,b){var c,a=[],e,h=[];if(0>=d.length)return b;for(e=0;e<d.length;e++)c=d[e],c=new jQuery.fn.jplist.PathModel(c,null),a.push(c);for(var g=0;g<b.length;g++){e=b[g];for(var f=0;f<a.length;f++)if(c=a[f],"default"===c.jqPath){h.push(e);break}else(c=e.findPathitem(c))&&h.push(e)}return h}})();(function(){jQuery.fn.jplist.FiltersService.rangeFilter=function(d,b,c,a,e,h){for(var g=[],f,k=[],l,m=0;m<b.length;m++)k=[],f=b[m],(c=f.findPathitem(d))&&0<c.$element.length&&(c.$element.each(function(){var a=Number(jQuery(this).text().replace(/[^-0-9\.]+/g,""));isNaN(a)||k.push(a)}),0<k.length&&(a=Math.max.apply(Math,k),c=Math.min.apply(Math,k),l=!0,jQuery.isNumeric(e)&&e>c&&(l=!1),jQuery.isNumeric(h)&&a>h&&(l=!1),l&&g.push(f)));return g}})();(function(){var d=function(b,a,e,h){var g;if(e<a.length)if(g=a[e],h=b.split(g),1<h.length)for(h={operator:g,nodes:h},g=0;g<h.nodes.length;g++)b=h.nodes[g],h.nodes[g]=d(b,a,e+1);else return d(b,a,e+1);else return jQuery.trim(jQuery.fn.jplist.HelperService.removeCharacters(b,h));return h},b=function(c,a,e,d,g){var f=!0;if(c.nodes){for(f=0;f<c.nodes.length;f++)b(c.nodes[f],a,e,d,g);f=c.nodes;c=c.operator;var k,l;k=-1!==a.indexOf(f[0]);for(var m=1;m<f.length;m++)l=f[m],""!==l&&(-1!==e.indexOf(c)&&(k=
    
    k&&-1===a.indexOf(l)),-1!==d.indexOf(c)&&(k=k&&-1!==a.indexOf(l)),-1!==g.indexOf(c)&&(k=k||-1!==a.indexOf(l)));f=k}else f=-1!==a.indexOf(c);return f};jQuery.fn.jplist.FiltersService.advancedSearchParse=function(c,a,e,h,g,f){var k;h&&0!=h.length||(h=["-"]);g&&0!=g.length||(g=["&&"]);f&&0!=f.length||(f=[","]);k=f.concat(g).concat(h);a=d(a,k,0,e);return b(a,c,h,g,f)}})();(function(){jQuery.fn.jplist.FiltersService.textGroupFilter=function(d,b,c,a,e,h){var g,f,k;h=[];var l,m;if(0>=d.length)return e;k=new jQuery.fn.jplist.PathModel(c,null);for(var n=0;n<e.length;n++)if(c=e[n],f=c.findPathitem(k),"default"===k.jqPath)h.push(c);else if(f)if(f=jQuery.fn.jplist.HelperService.removeCharacters(f.text,a),"or"===b)for(m=0;m<d.length;m++){if(g=d[m],g=jQuery.fn.jplist.HelperService.removeCharacters(g,a),jQuery.fn.jplist.FiltersService.advancedSearchParse(f,g)){h.push(c);break}}else{l=
    
    [];for(m=0;m<d.length;m++)g=d[m],g=jQuery.fn.jplist.HelperService.removeCharacters(g,a),jQuery.fn.jplist.FiltersService.advancedSearchParse(f,g)&&l.push(g);l.length===d.length&&h.push(c)}return h}})();(function(){jQuery.fn.jplist.FiltersService.textFilter=function(d,b,c,a,e,h,g,f){var k,l,m=[],n;e=e||"contains";for(var p=0;p<c.length;p++)if(k=c[p],l=k.findPathitem(b),"default"===b.jqPath)m.push(k);else if(l)switch(l=jQuery.trim(jQuery.fn.jplist.HelperService.removeCharacters(l.text,a)),n=jQuery.trim(jQuery.fn.jplist.HelperService.removeCharacters(d,a)),e){case "startsWith":l.startsWith(n)&&m.push(k);break;case "endsWith":l.endsWith(n)&&m.push(k);break;case "advanced":jQuery.fn.jplist.FiltersService.advancedSearchParse(l,
    
    n,a,h,g,f)&&m.push(k);break;case "equal":l===n&&m.push(k);break;default:-1!==l.indexOf(n)&&m.push(k)}return m}})();(function(){jQuery.fn.jplist.FiltersService.textFilterPathGroup=function(d,b,c,a){var e,h;a=[];var g=[],f,k,l;for(l=0;l<d.length;l++)k=d[l],k.selected&&(e=k.path,h=new jQuery.fn.jplist.PathModel(e,null),k.pathObj=h,a.push(k));if(0>=a.length)return c;for(e=0;e<c.length;e++){d=c[e];l=!1;for(var m=0;m<a.length;m++)if(k=a[m],h=k.pathObj)if("default"===h.jqPath){l=!0;break}else if(h=d.findPathitem(h))switch(h=jQuery.trim(jQuery.fn.jplist.HelperService.removeCharacters(h.text,b)),f=jQuery.trim(jQuery.fn.jplist.HelperService.removeCharacters(k.text,
    
    b)),k.mode){case "startsWith":h.startsWith(f)&&(l=!0);break;case "endsWith":h.endsWith(f)&&(l=!0);break;case "advanced":jQuery.fn.jplist.FiltersService.advancedSearchParse(h,f)&&(l=!0);break;case "equal":h===f&&(l=!0);break;default:-1!==h.indexOf(f)&&(l=!0)}l&&g.push(d)}return g}})();(function(){jQuery.fn.jplist.SortService.datetimeHelper=function(d,b,c,a,e){d=d.findPathitem(a);b=b.findPathitem(a);return d&&b?(jQuery.trim(e)?(a=jQuery.fn.jplist.HelperService.formatDateTime(d.text,e),e=jQuery.fn.jplist.HelperService.formatDateTime(b.text,e)):(a=new Date(Date.parse(d.text)),e=new Date(Date.parse(b.text))),a==e?0:"asc"==c?a>e?1:-1:a<e?1:-1):0};jQuery.fn.jplist.SortService.datetime=function(d,b,c,a){c.sort(function(c,h){return jQuery.fn.jplist.SortService.datetimeHelper(c,h,d,b,a)})}})();(function(){jQuery.fn.jplist.SortService.numbersHelper=function(d,b,c,a){d=d.findPathitem(a);b=b.findPathitem(a);return d&&b?(a=parseFloat(d.text.replace(/[^-0-9\.]+/g,"")),b=parseFloat(b.text.replace(/[^-0-9\.]+/g,"")),c=a==b?0:"asc"==c?isNaN(a)?1:isNaN(b)?-1:a-b:isNaN(a)?1:isNaN(b)?-1:b-a):0};jQuery.fn.jplist.SortService.numbers=function(d,b,c){c.sort(function(a,c){return jQuery.fn.jplist.SortService.numbersHelper(a,c,d,b)})}})();(function(){jQuery.fn.jplist.SortService.textHelper=function(d,b,c,a,e){d=d.findPathitem(a);b=b.findPathitem(a);return d&&b?(e?(a=new RegExp(e,"ig"),e=d.text.toString().replace(a,"").toLowerCase(),d=b.text.toString().replace(a,"").toLowerCase()):(e=d.text.toString().toLowerCase(),d=b.text.toString().toLowerCase()),e===d?0:"".localeCompare?"asc"===c?e.localeCompare(d):d.localeCompare(e):"asc"===c?e>d?1:-1:e<d?1:-1):0};jQuery.fn.jplist.SortService.text=function(d,b,c,a){c.sort(function(c,h){return jQuery.fn.jplist.SortService.textHelper(c,
    
    h,d,b,a)})}})();(function(){var d=function(b,c,a,e){var h=0,g,f;0<a.length&&(g=a[e],"default"!==g.data.path?(f=jQuery.fn.jplist.DTOMapperService.sort[g.data.type],jQuery.isFunction(f)&&(h=f(g,b,c))):(h=b.index,g=c.index,h=h===g?0:isNaN(h)?1:isNaN(g)?-1:h-g),0===h&&e+1<a.length&&(h=d(b,c,a,e+1)));return h};jQuery.fn.jplist.SortService.doubleSort=function(b,c){var a=!1;1===b.length&&b[0]&&b[0].data&&"default"===b[0].data.path&&(a=!0);a||c.sort(function(a,c){return d(a,c,b,0)});return c}})();(function(){jQuery.jplist.sort=function(d,b,c,a,e,h,g){("asc"!==b&&"desc"!==b||"text"!==c&&"number"!==c&&"datetime"!==c)&&console.log("valdation error");b={action:"sort",name:"sort",type:"sort",data:{path:a,type:c,order:b,dateTimeFormat:h,ignore:e},inStorage:!1,inAnimation:!1,isAnimateToTop:!1,inDeepLinking:!1};a=[new jQuery.fn.jplist.PathModel(a,c)];c=new jQuery.fn.jplist.PubSub(jQuery("<div></div>"),{});d=new jQuery.fn.jplist.Dataitems(c,d,a);d.sort([b]);return"html"===g?d.dataviewToString():d.dataviewToJqueryObject()}})();(function(){var d=function(b,a){var e=null;if("{month}"==b){a=a.toLowerCase();if("january"===a||"jan"===a||"jan."===a)e=0;if("february"===a||"feb"===a||"feb."===a)e=1;if("march"===a||"mar"===a||"mar."===a)e=2;if("april"==a||"apr"===a||"apr."===a)e=3;"may"===a&&(e=4);if("july"==a||"jun"===a||"jun."===a)e=5;if("april"===a||"jul"===a||"jul."===a)e=6;if("august"===a||"aug"===a||"aug."===a)e=7;if("september"===a||"sep"===a||"sep."===a)e=8;if("october"===a||"oct"===a||"oct."===a)e=9;if("november"===a||
    
    "nov"===a||"nov."===a)e=10;if("december"===a||"dec"===a||"dec."===a)e=11;null===e&&(e=parseInt(a,10),isNaN(e)||e--)}else e=parseInt(a,10);return e},b=function(b,a){var e,d=null;e=b.replace(/{year}|{month}|{day}|{hour}|{min}|{sec}/g,".*");(e=(new RegExp(e,"g")).exec(a))&&1<e.length&&(d=e[1]);return d};jQuery.fn.jplist.HelperService={};jQuery.fn.jplist.HelperService.getOuterHtml=function(b){var a="",e,d;if(b&&b[0]&&b[0].tagName){e=b[0].attributes;d=b.html();b=b[0].tagName.toString().toLowerCase();for(var a=
    
    a+("<"+b),g=0;g<e.length;g++)e[g].nodeValue&&(a+=" "+e[g].nodeName+"=",a+='"'+e[g].nodeValue+'"');a=a+">"+d;a+="</"+b+">"}return a};jQuery.fn.jplist.HelperService.removeCharacters=function(b,a){return b?b.replace(new RegExp(a,"ig"),"").toLowerCase():""};jQuery.fn.jplist.HelperService.formatDateTime=function(c,a){var e,h,g,f,k,l;a=a.replace(/\./g,"\\.");a=a.replace(/\(/g,"\\(");a=a.replace(/\)/g,"\\)");a=a.replace(/\[/g,"\\[");a=a.replace(/\]/g,"\\]");e=a.replace("{year}","(.*)");(h=b(e,c))&&(h=d("{year}",
    
    h));e=a.replace("{day}","(.*)");(f=b(e,c))&&(f=d("{day}",f));e=a.replace("{month}","(.*)");(g=b(e,c))&&(g=d("{month}",g));e=a.replace("{hour}","(.*)");(k=b(e,c))&&(k=d("{hour}",k));e=a.replace("{min}","(.*)");(l=b(e,c))&&(l=d("{min}",l));e=a.replace("{sec}","(.*)");(e=b(e,c))&&(e=d("{sec}",e));if(!h||isNaN(h))h=1900;if(!g||isNaN(g))g=0;if(!f||isNaN(f))f=1;if(!k||isNaN(k))k=0;if(!l||isNaN(l))l=0;if(!e||isNaN(e))e=0;return new Date(h,g,f,k,l,e)}})();(function(){jQuery.fn.jplist.DeepLinksService={};jQuery.fn.jplist.DeepLinksService.getUrlParams=function(d){var b=[],c,a=[],e,b=window.decodeURIComponent(jQuery.trim(window.location.hash.replace(d.hashStart,"")));if(d.deepLinking&&""!==jQuery.trim(b))for(var b=b.split(d.delimiter1),h=0;h<b.length;h++)c=b[h],e=c.split("="),2===e.length&&(c=e[0],e=e[1],c=c.split(d.delimiter0),2===c.length&&(c={controlName:c[0],propName:c[1],propValue:e},a.push(c)));return a};jQuery.fn.jplist.DeepLinksService.updateUrlPerControls=
    
    function(d,b){if(d.deepLinking){var c=jQuery.trim(b.replace(d.hashStart,"")),a,c=""===c?d.hashStart:d.hashStart+c;window.location.hash!==c&&(a=window.location.href.indexOf(d.hashStart),c=-1==a?window.location.href+c:window.location.href.substring(0,a)+c,"replaceState"in window.history?window.history.replaceState("","",c):window.location.replace(c))}}})();(function(){var d=function(b,c,a,e){var d=c.dataitemsToJqueryObject(),g=c.dataviewToJqueryObject(),f=!1,k=jQuery.extend(!0,{},b.options,{duration:0});0>=d.length||0>=g.length?(b.$noResults.removeClass("jplist-hidden"),b.$itemsBox.addClass("jplist-hidden"),jQuery.isFunction(b.redrawCallback)&&b.redrawCallback(c,g,a)):(b.$noResults.addClass("jplist-hidden"),b.$itemsBox.removeClass("jplist-hidden"),b.effect&&jQuery.fn.jplist.animation?(e&&!e.inAnimation&&(f=!0),e=f?k:b.options,jQuery.fn.jplist.animation.drawItems(e,
    
    b.$itemsBox,d,g,b.effect,function(){jQuery.isFunction(b.redrawCallback)&&b.redrawCallback(c,g,a)},b.observer)):(d.detach(),b.$itemsBox.append(g),jQuery.isFunction(b.redrawCallback)&&b.redrawCallback(c,g,a)));return g};jQuery.fn.jplist.DOMView=function(b,c,a,e,d,g,f){this.options=c;this.$root=b;this.observer=a;this.redrawCallback=g;this.effect=f;this.$itemsBox=b.find(e).eq(0);this.$noResults=b.find(d)};jQuery.fn.jplist.DOMView.prototype.render=function(b,c,a){return d(this,b,c,a)}})();(function(){jQuery.fn.jplist.DOMController=function(d,b,c,a){this.observer=c;this.$root=d;this.listView=new jQuery.fn.jplist.DOMView(d,b,c,b.itemsBox,b.noResults,b.redrawCallback,b.effect);d=b.itemPath;b=this.$root.find(b.itemsBox).eq(0).find(d);this.collection=new jQuery.fn.jplist.Dataitems(this.observer,b,a)};jQuery.fn.jplist.DOMController.prototype.renderStatuses=function(d,b){var c;this.collection&&(this.collection.applyStatuses(d),c=this.listView.render(this.collection,d,b));return c};jQuery.fn.jplist.DOMController.prototype.addPaths=
    
    function(d){this.collection.addPaths(d)}})();(function(){jQuery.fn.jplist.ItemControlFactory=function(d,b,c,a){this.options=d;this.observer=b;this.history=c;this.$root=a};jQuery.fn.jplist.ItemControlFactory.prototype.create=function(d){var b=null,c,a,e=null,h=null;c=d.attr("data-control-type");a={};jQuery.fn.jplist.itemControlTypes[c]&&(a=jQuery.extend(!0,{},a,jQuery.fn.jplist.itemControlTypes[c]));this.options.itemControlTypes&&this.options.itemControlTypes[c]&&(a=jQuery.extend(!0,{},a,this.options.itemControlTypes[c]));a&&(a.className&&(e=
    
    jQuery.fn.jplist.itemControls[a.className]),a.options&&(h=a.options));c={type:c,itemControlType:a,controlTypeClass:e,controlOptions:h};c=jQuery.extend(!0,c,{$control:d,history:this.history,observer:this.observer,options:this.options,$root:this.$root});c.controlTypeClass&&jQuery.isFunction(c.controlTypeClass)&&(b=new c.controlTypeClass(c));return b}})();(function(){var d=function(b,c){c&&0<c.length&&c.find("[data-control-type]").each(function(){var a=jQuery(this);(a=b.controlFactory.create(a))&&b.controls.push(a)})};jQuery.fn.jplist.ItemControlCollection=function(b,c,a,e){this.options=b;this.observer=c;this.history=a;this.$root=e;this.controls=[];this.controlFactory=new jQuery.fn.jplist.ItemControlFactory(b,c,a,e);this.options&&this.options.itemsBox&&d(this,this.$root.find(this.options.itemsBox))}})();(function(){var d=function(a){var b=jQuery(window).scrollTop(),c;c=Number(a.data("top"));isNaN(c)||(b>c?a.addClass("jplist-sticky"):a.removeClass("jplist-sticky"))},b=function(a,b){b.each(function(){var a=jQuery(this),b=a.offset().top;a.data("top",b);d(a)});jQuery(window).scroll(function(){b.each(function(){d(jQuery(this))})})},c=function(b){var c=[];if("cookies"===b.options.storage||"localstorage"===b.options.storage&&jQuery.fn.jplist.LocalStorageService.supported())if("cookies"===b.options.storage&&
    
    (c=jQuery.fn.jplist.CookiesService.restoreCookies(b.options.storageName)),"localstorage"===b.options.storage&&jQuery.fn.jplist.LocalStorageService.supported()&&(c=jQuery.fn.jplist.LocalStorageService.restore(b.options.storageName)),0<c.length){for(var d=[],f=0;f<c.length;f++)c[f].inStorage&&d.push(c[f]);0<d.length&&(b.controls.setStatuses(d,!0),b.observer.trigger(b.observer.events.knownStatusesChanged,[d]))}else a(b,!0);else a(b,!0)},a=function(a,b){var c;c=a.controls.getStatuses(b);0<c.length&&a.observer.trigger(a.observer.events.knownStatusesChanged,
    
    [c])};jQuery.fn.jplist.PanelController=function(a,c,d,f){this.options=c;this.$root=a;this.history=d;this.observer=f;this.controls=this.paths=this.$sticky=null;c=this.$root.find(this.options.panelPath).find("[data-control-type]");this.controls=new jQuery.fn.jplist.ControlsCollection;this.controls.addList(c,this.history,this.$root,this.options,this.observer);this.paths=this.controls.getPaths();this.$sticky=a.find('[data-sticky="true"]');0<this.$sticky.length&&b(this,this.$sticky)};jQuery.fn.jplist.PanelController.prototype.addControl=
    
    function(a){this.controls.add(a,this.history,this.$root,this.options,this.observer);this.paths=this.controls.getPaths()};jQuery.fn.jplist.PanelController.prototype.setStatusesByDeepLink=function(){var a;a=jQuery.fn.jplist.DeepLinksService.getUrlParams(this.options);0>=a.length?c(this):this.controls.setDeepLinks(a,this.observer)};jQuery.fn.jplist.PanelController.prototype.setStatusesFromStorage=function(){c(this)};jQuery.fn.jplist.PanelController.prototype.setStatuses=function(a){this.history.addStatuses(a);
    
    this.controls.setStatuses(a,!1);this.history.addList(a)};jQuery.fn.jplist.PanelController.prototype.getStatuses=function(a){return this.controls.getStatuses(a)};jQuery.fn.jplist.PanelController.prototype.mergeStatuses=function(a){return this.controls.merge(!1,a)};jQuery.fn.jplist.PanelController.prototype.statusesChangedByDeepLinks=function(a,b){this.controls&&this.controls.statusesChangedByDeepLinks(b)};jQuery.fn.jplist.PanelController.prototype.getDeepLinksURLPerControls=function(){return this.controls.getDeepLinksUrl(this.options.delimiter1)}})();(function(){jQuery.fn.jplist.ControlFactory=function(d,b,c,a){this.options=d;this.observer=b;this.history=c;this.$root=a};jQuery.fn.jplist.ControlFactory.prototype.create=function(d,b){var c=null,a,e,h,g,f,k,l,m;a=d.attr("data-control-type");f=g=h=!0;k=!1;m=l=null;(e=d.attr("data-control-deep-link"))&&"false"===e.toString()&&(h=!1);(e=d.attr("data-control-storage"))&&"false"===e.toString()&&(g=!1);(e=d.attr("data-control-animation"))&&"false"===e.toString()&&(f=!1);(e=d.attr("data-control-animate-to-top"))&&
    
    "true"===e.toString()&&(k=!0);e={};jQuery.fn.jplist.controlTypes[a]&&(e=jQuery.extend(!0,{},e,jQuery.fn.jplist.controlTypes[a]));this.options.controlTypes&&this.options.controlTypes[a]&&(e=jQuery.extend(!0,{},e,this.options.controlTypes[a]));e&&(e.className&&(l=jQuery.fn.jplist.controls[e.className]),e.options&&(m=e.options));a={type:a,action:d.attr("data-control-action"),name:d.attr("data-control-name"),inDeepLinking:h,inStorage:g,inAnimation:f,isAnimateToTop:k,controlType:e,controlTypeClass:l,controlOptions:m,
    
    paths:[]};a=jQuery.extend(!0,a,{$control:d,history:this.history,observer:this.observer,options:this.options,$root:this.$root,controlsCollection:b});a.controlTypeClass&&jQuery.isFunction(a.controlTypeClass)&&(c=new a.controlTypeClass(a));return c};jQuery.fn.jplist.ControlFactory.prototype.getStatus=function(d){return null};jQuery.fn.jplist.ControlFactory.prototype.setStatus=function(d,b){};jQuery.fn.jplist.ControlFactory.prototype.getDeepLink=function(){return""};jQuery.fn.jplist.ControlFactory.prototype.getStatusByDeepLink=
    
    function(d,b){return null};jQuery.fn.jplist.ControlFactory.prototype.getPaths=function(d){return[]};jQuery.fn.jplist.ControlFactory.prototype.setByDeepLink=function(d){};jQuery.fn.jplist.ControlFactory.getProp=function(d,b){var c=[],a=new RegExp("^"+b+"[0-9]*$");jQuery.each(d.data(),function(b,d){a.test(b)&&c.push(d)});return 0==c.length?"":1==c.length?c[0]:c};jQuery.fn.jplist.ControlFactory.getPropPath=function(d,b){var c="";if(jQuery.isArray(d))for(var a=0;a<d.length;a++){var e="";0!==a&&(e=""+
    
    a);c+="[data-"+b+e+'="'+d[a]+'"]'}else c+="[data-"+b+'="'+d+'"]';return c}})();(function(){var d=function(a,b){for(var c=[],d,f=0;f<a.controls.length;f++)d=a.controls[f],jQuery.isFunction(d.getStatus)&&(d=d.getStatus(b))&&jQuery.fn.jplist.StatusesService.add(c,d,!1);return c},b=function(a,b,c,d,f,k){(b=(new jQuery.fn.jplist.ControlFactory(f,k,c,d)).create(b,a))&&a.controls.push(b);return b},c=function(a,c,d,g,f,k){c.each(function(){b(a,jQuery(this),d,g,f,k)})};jQuery.fn.jplist.ControlsCollection=function(){this.controls=[]};jQuery.fn.jplist.ControlsCollection.prototype.merge=
    
    function(a,b){var c,g;g=[];c=d(this,a);for(var f=0;f<c.length;f++)jQuery.fn.jplist.StatusesService.add(g,c[f],!1);if(b)for(c=0;c<b.length;c++)jQuery.fn.jplist.StatusesService.add(g,b[c],!0);return g};jQuery.fn.jplist.ControlsCollection.prototype.statusesChangedByDeepLinks=function(a){for(var b,c=0;c<this.controls.length;c++)b=this.controls[c],jQuery.isFunction(b.setByDeepLink)&&b.setByDeepLink(a)};jQuery.fn.jplist.ControlsCollection.prototype.setDeepLinks=function(a,b){var c,d,f,k=[],l;for(l=0;l<
    
    a.length;l++){c=a[l];d=c.controlName;var m=[];f=void 0;for(var n=0;n<this.controls.length;n++)f=this.controls[n],f.name===d&&m.push(f);d=m;for(m=0;m<d.length;m++)f=d[m],jQuery.isFunction(f.getStatusByDeepLink)&&(f=f.getStatusByDeepLink(c.propName,c.propValue))&&jQuery.fn.jplist.StatusesService.add(k,f,!1)}b.trigger(b.events.knownStatusesChanged,[k]);b.trigger(b.events.statusesChangedByDeepLinks,[,k,a])};jQuery.fn.jplist.ControlsCollection.prototype.setStatuses=function(a,b){for(var c,d,f=[],k=0;k<
    
    a.length;k++){c=a[k];d=!1;for(var l=0;l<f.length;l++)f[l].name===c.name&&f[l].action===c.action&&(d=!0,f[l].statuses.push(c));d||f.push({name:c.name,action:c.action,statuses:[c]})}for(l=0;l<f.length;l++)if(c=f[l],c.statuses&&0<c.statuses.length){d=c.statuses[0].name;for(var k=c.statuses[0].action,m=[],n=void 0,p=0;p<this.controls.length;p++)n=this.controls[p],n.name===d&&n.action===k&&m.push(n);d=m;for(k=0;k<d.length;k++)jQuery.isFunction(d[k].setStatus)&&(1<c.statuses.length?d[k].setStatus(c.statuses,
    
    b):d[k].setStatus(c.statuses[0],b))}};jQuery.fn.jplist.ControlsCollection.prototype.getDeepLinksUrl=function(a){var b;b="";var c=[],d="",f;f=this.controls;for(var k=0;k<f.length;k++)b=f[k],jQuery.isFunction(b.getDeepLink)&&(d=jQuery.trim(b.getDeepLink())),""!==d&&-1===jQuery.inArray(d,c)&&c.push(d);return b=c.join(a)};jQuery.fn.jplist.ControlsCollection.prototype.getStatuses=function(a){return d(this,a)};jQuery.fn.jplist.ControlsCollection.prototype.getPaths=function(){for(var a,b=[],c=[],d=0;d<this.controls.length;d++)if(a=
    
    this.controls[d],jQuery.isFunction(a.getPaths))for(a.getPaths(b),a=0;a<b.length;a++){for(var f=c,k=b[a],l=void 0,m=!1,n=0;n<f.length;n++)if(l=f[n],l.isEqual(k,!0)){m=!0;break}m||c.push(b[a])}return c};jQuery.fn.jplist.ControlsCollection.prototype.add=function(a,c,d,g,f){b(this,a,c,d,g,f)};jQuery.fn.jplist.ControlsCollection.prototype.addList=function(a,b,d,g,f){c(this,a,b,d,g,f)}})();(function(){var d=function(a){var b=[];a=jQuery();jQuery(document).find("[data-control-type]").each(function(){var a=jQuery(this),c=a.attr("data-control-type");c&&jQuery.fn.jplist.controlTypes[c]&&jQuery.fn.jplist.controlTypes[c].dropdown&&b.push(a)});for(var c=0;c<b.length;c++)a=a.add(b[c]);return a},b=function(a){var b=d(a);0<b.length&&(jQuery(document).click(function(){b.find("ul").hide()}),jQuery(document).off(a.DROPDOWN_CLOSE_EVENT).on(a.DROPDOWN_CLOSE_EVENT,function(a,c){b.each(function(){jQuery(this).is(c)||
    
    jQuery(this).find("ul").hide()})}));a.$control.find(".jplist-dd-panel").off().on("click",function(b){var c;b.stopPropagation();b=jQuery(this).parents("[data-control-type]");c=b.find("ul");jQuery(document).trigger(a.DROPDOWN_CLOSE_EVENT,[b]);c.toggle(0)})},c=function(a,c,d,g){a={options:a,observer:c,history:d,$control:g,DROPDOWN_CLOSE_EVENT:"dropdown-close-event"};c=a.$control.find("li:eq(0)");c.addClass("active");c=c.find("span");0>=a.$control.find(".jplist-dd-panel").length&&a.$control.prepend('<div class="jplist-dd-panel">'+
    
    c.text()+"</div>");b(a);return jQuery.extend(this,a)};jQuery.fn.jplist.DropdownControl=function(a,b,d,g){return new c(a,b,d,g)}})();(function(){jQuery.fn.jplist.Storage=function(d,b,c){this.storageType=d;this.storageName=b;this.cookiesExpiration=c;this.isStorageEnabled="cookies"===d||"localstorage"===d&&jQuery.fn.jplist.LocalStorageService.supported()};jQuery.fn.jplist.Storage.prototype.save=function(d){var b=[],c;if(d&&this.isStorageEnabled){for(var a=0;a<d.length;a++)c=d[a],c.inStorage&&b.push(c);"cookies"===this.storageType&&jQuery.fn.jplist.CookiesService.saveCookies(b,this.storageName,this.cookiesExpiration);"localstorage"===
    
    this.storageType&&jQuery.fn.jplist.LocalStorageService.supported()&&jQuery.fn.jplist.LocalStorageService.save(b,this.storageName)}}})();(function(){jQuery.fn.jplist.CookiesService={};jQuery.fn.jplist.CookiesService.setCookie=function(d,b,c){b=escape(b);var a=new Date;c=Number(c);-1==c||isNaN(c)?document.cookie=d+"="+b+";path=/;":(a.setMinutes(a.getMinutes()+c),document.cookie=d+"="+b+";path=/; expires="+a.toUTCString())};jQuery.fn.jplist.CookiesService.getCookie=function(d){var b,c,a,e=null;a=document.cookie.split(";");for(var h=0;h<a.length;h++)if(b=a[h].substr(0,a[h].indexOf("=")),c=a[h].substr(a[h].indexOf("=")+1),b=b.replace(/^\s+|\s+$/g,
    
    ""),b==d){e=unescape(c);break}return e};jQuery.fn.jplist.CookiesService.saveCookies=function(d,b,c){d=JSON.stringify(d);jQuery.fn.jplist.CookiesService.setCookie(b,d,c)};jQuery.fn.jplist.CookiesService.restoreCookies=function(d){var b=[];(d=jQuery.fn.jplist.CookiesService.getCookie(d))&&(b=jQuery.parseJSON(d));b||(b=[]);return b}})();(function(){jQuery.fn.jplist.LocalStorageService={};jQuery.fn.jplist.LocalStorageService.supported=function(){try{return"localStorage"in window&&null!==window.localStorage}catch(d){return!1}};jQuery.fn.jplist.LocalStorageService.save=function(d,b){var c;c=JSON.stringify(d);window.localStorage[b]=c};jQuery.fn.jplist.LocalStorageService.restore=function(d){var b=[];(d=window.localStorage[d])&&(b=jQuery.parseJSON(d));b||(b=[]);return b}})();
    
    